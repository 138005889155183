@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.accordion {
	position: relative;
	overflow: hidden;

	.toggleFilter {
		padding: 14px 10px 24px 32px;

		@include maxWidth($mobile) {
			padding-left: 20px;
		}

		.toggleContentFilter {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}
	}

	.toggleFooter {
		padding: 18px 0;

		.toggleContentFooter {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		display: block;
		width: calc(100% - 20px);
		height: 1px;
		background: $gray-1;
	}

	&.firstBorder {
		&:first-child {
			&::before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				display: block;
				width: calc(100% - 20px);
				height: 1px;
				background: $gray-1;
			}
		}
	}

	&.product {
		&:first-child {
			border-top: 1px solid $gray-1;
		}

		&::after {
			width: 100%;
			background: $gray-1;
		}

		.toggle {
			padding: 16px 0 24px 0;
			font-family: $fontFutura;
			font-weight: 500;
			font-size: 18px;

			.toggleContent {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}

			&::before,
			&::after {
				right: 0;
			}
		}

		.content {
			padding-left: 0;
			padding-right: 0;
			color: $black-3;

			@include maxWidth($mTablet) {
				max-width: 520px;
			}

			p {
				margin-bottom: 8px;
				white-space: pre-wrap;
			}
		}
	}

	&.productShop {
		border: 1px solid $gray-1;
		border-radius: 4px;

		&::after {
			content: none;
		}

		.toggle {
			min-height: 48px;
			padding: 0 18px 0 0;

			&::before,
			&::after {
				right: 16px;
				top: calc(50% - 2px);
			}
		}

		.content {
			max-height: 228px;
			padding: 0;
			overflow: auto;
			border-top: 1px solid $gray-1;
		}
	}

	&.mobileFilterCategory {
		.content {
			padding-left: 0;
			padding-right: 0;
		}

		& & {
			.content {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}

	&.cart {
		&::after {
			width: 100%;
		}

		.toggle {
			@include subtitle;
			padding: 16px 0 48px;
			color: $black-1;

			@include maxWidth($tablet + 1) {
				@include subtitle-mobile;
				padding-bottom: 32px;
			}
		}

		.toggleContent {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.toggleIcon {
			margin-right: -6px;
		}

		.content {
			padding: 0 0 32px;

			@include maxWidth($tablet + 1) {
				padding: 0 0 24px;
			}
		}
	}
}

.toggle {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 13px 58px 20px 19px;
	font-size: 20px;
	color: $black-1;
	text-align: left;
	cursor: pointer;
	transition: color 0.25s ease-out;

	.toggleIcon {
		position: relative;
		width: 24px;
		height: 36px;

		.iconHorizontal,
		.iconVertical {
			transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
			transform-origin: 50% 50%;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	&.open {
		.toggleIcon {
			.iconHorizontal {
				opacity: 0;
				transform: rotate(180deg);
			}

			.iconVertical {
				transform: rotate(90deg);
				transform-origin: 150%;
			}
		}
	}

	&.disabled {
		color: $black-4;
		cursor: default;

		&::before,
		&::after {
			content: none;
		}
	}

	&:hover {
		color: $black-2;
	}
}

.content {
	padding: 0 20px 24px;
}

.contentFilter {
	padding: 0 20px 16px 32px;

	@include maxWidth($mobile) {
		padding-left: 20px;
	}
}

.contentFooter {
	padding: 0 0 12px 0;
}

.footer {
	&::after {
		width: 100%;
	}
}
